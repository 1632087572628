import * as React from "react";
import { DevelopmentContents, Container, Breadcrumb } from "../../components";
import Seo from "../../components/Seo";

const DesignDevelopmentPage = () => {
  return (
    <Container>
      <Seo
        title="Web開発事業"
        description="TKCホールディングのWeb開発事業では、クライアントと一緒に一つのチームとなり並走するパートナーとなります。新規事業の立ち上げや既存事業のリニューアル、DX推進などあらゆる課題に最新のモダン技術を駆使することで、クライアントの課題解決を目指します。"
      />
      <DevelopmentContents />
      <Breadcrumb
        currentPage="Design & Development"
        currentSlug="/services/design-development"
        parentPage="Services"
        parentSlug="/services"
      />
    </Container>
  );
};

export default DesignDevelopmentPage;
